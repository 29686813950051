import React from 'react'

import theme from '../theme/theme'

class Register extends React.Component {
	componentDidMount() {
		window.location.replace(theme.redirects.register)
	}

	render () {
		return true
	}
}

export default Register
